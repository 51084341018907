import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, AfterContentChecked, AfterViewChecked, ElementRef,QueryList  } from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {Clipboard} from '@angular/cdk/clipboard';
import { FormGroup,FormsModule }   from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, DatePipe } from '@angular/common';



//Services etc
import { ProfileService } from '../../services/profile.service';
import { ContentManagerService } from '../../services/content-manager.service';
import { AssetsService } from '../../services/assets.service';
import { S3UploadService } from '../../services/s3-upload-service.service';


@Component({
  selector: 'app-asset-library',
  templateUrl: './asset-library.component.html',
  styleUrls: ['./asset-library.component.css']
})
export class AssetLibraryComponent implements OnInit  {
	//, AfterContentChecked
	@ViewChild('sectionassets') sectionassets: ElementRef;
	select_open_network=false;
	select_open_campaign=false;
	select_open_type=false;
	select_open_title=false;
	select_open_tag=false;
	select_open_sort=false;
	filters=['network', 'campaign','type', 'title', 'tag', 'sort'];
//	current_network=null;
//	current_campaign=null;
//	current_type=null;
//	current_title=null;
//	current_tag=null;
//	current_sort="asset_start";
	current_asset=null;
	asc=false;
	reverse="asc";
	direction="↓";
	authorized=true;
	Networks: any |[];
	Campaigns: any |[];
	Profile: any |[];
	Assets: any |[];
	FilteredAssets:any |[];
	FilteredNetworks:any |[];
	FilteredCampaigns:any |[];
	FilteredTags:any |[];
	FilteredTitles:any |[];
	FilteredTypes:any |[];
	Titles: any |[];
	Tags: any |[];
	Types: any |[];
	target=null;
	crit=null;
	sorts=[
		{label:'Published Date', column:'last_updated'},
		{label:'Usage Start', column:'asset_start'},
		{label:'Usage End', column:'asset_end'},
		{label:'Title', column:'asset_name'},
		{label:'Asset Type', column:'asset_type'}	
	];
	
	private ngUnsubscribe = new Subject<void>();
//    @ViewChildren('filteredAssets') containers: QueryList<ElementRef>;
//  containersCount: number;

   
	
  constructor(public PS:ProfileService,public datepipe: DatePipe, public AS: AssetsService, public CS: ContentManagerService, public route: ActivatedRoute, public router:Router, public S3S:S3UploadService) { }

  ngOnInit(): void {
	  ///:id/:criteria
	  this.route.paramMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
		  this.target=params.get('id');
		  this.crit=params.get('criteria');
		  console.log('params'+this.target+'crit'+this.crit);
		  if(this.target=="search"){
			  this.AS.assetSearch=decodeURIComponent(this.crit);
			  console.log('search is', decodeURIComponent(this.crit));
		  }else if(this.target){
			  this.clearAll();
			  console.log('target',this.target);
		  }
	  });
	  
	  
	  this.PS.getProfile().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Profile = data; console.log('profile', this.Profile.profile_id);
		   if(this.crit=='add' && this.target>0 && this.Profile.profile_id>0){this.addtoQ(this.target);}
	  });
	  this.CS.getNetworks().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Networks=data;
		  this.FilteredNetworks=this.Networks;
		  if(this.crit=='network'){this.AS.current_network=this.getColumn(this.target, this.Networks, 'network_full','network_short');}
	  });
	  this.CS.getCampaigns().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Campaigns=data;
		  if(this.crit=='campaign'){this.AS.current_campaign=this.getColumn(this.target, this.Campaigns, 'campaign_name','campaign_id');}

	  });
      this.CS.getTags().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { this.Tags=data; });
	  this.CS.getTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { this.Types=this.removeNtwkAssets(data); });
	  this.AS.updateAssets(0);
	  this.AS.getAssets().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Assets=data;
		  if(this.crit=='asset'){
			  this.AS.current_title=this.getColumn(this.target, this.Assets, 'asset_name','asset_id');
	//		  console.log('title', this.AS.current_title);
	//		  if(this.AS.current_title==null){
	//			  this.authorized=false;
	//		  }else{
	//			  this.getFilteredAssets();
			  }
	//	  }else{
			  this.getFilteredAssets();
	//	  }
		  
		  console.log(this.Assets);console.log('assetsearch',this.AS.assetSearch);
		  console.log(this.FilteredAssets);
	  });
	 
	  
  }
  ngOnDestroy(){
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
  }
	lastUpdate(dt){
		//dt=dt+'T00:00:00.000-04:00';
		dt = new Date(dt);
		dt = dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate()+'T00:00:00.000-04:00';
		let newDate=new Date(dt);
		let latest_date =this.datepipe.transform(newDate, 'mediumDate');
		return latest_date;
	}
	goBack(): void {
    	window.history.back();
	}
	processLink(crit,id){
		if(crit=='campaign'){
		//	console.log('camp'+this.Campaigns.length);
			this.AS.current_campaign=this.getColumn(id, this.Campaigns, 'campaign_name','campaign_id');
		}else if(crit=='asset'){
			this.AS.current_title=this.getColumn(id, this.Assets, 'asset_name','asset_id');
		}else if(crit=='network'){
			this.AS.current_network=this.getColumn(id, this.Networks, 'network_full','network_short');
		}else if(crit=='add' && id>0 && this.Profile.profile_id>0){
			this.addtoQ(id);
		}
	}
	removeNtwkAssets(data){
		var arr=[];
		data.forEach(function(obj){
			if(obj.asset_type!=="Network Assets" && obj.asset_type!=="Social Media"){
				arr.push(obj);
			}
		});
		return arr;
	}
	
	getColumn(id,data,itm,col){
		var result=null;
		data.forEach(function(obj){
			if(obj[col]==id){
				result=obj[itm];
			}
		});
		return result;
	}
	
	toggleSelectOpen(el){
		this['select_open_'+el]=!this['select_open_'+el];
		var that=this;
		this.filters.forEach(function(obj){
			if(obj!==el){that['select_open_'+obj]=false;}
		})
	}
	select_option(el,val){
		if(el=='sort'){
			if(this.AS['current_'+el]!==val.column){
				this.AS['current_'+el]=val.column;
				this.reverse="asc";
			}else if(this.reverse=="asc"){
				this.reverse="desc";
			}else{
			    this.reverse="asc";
			}
			console.log(val.column, this.reverse);
			this.direction=(this.reverse=='asc'?'↓':'↑');
		}else{
			console.log('current_'+el);
			this.AS['current_'+el]=val;
			console.log(this.AS['current_'+el]);
			console.log(this.AS.current_network);
		}
		this.getFilteredAssets();
	}
	getSortLabel(itm){
		var lbl=null;
		this.sorts.forEach(function(obj){
			if(obj.column==itm){ lbl=obj.label;}
		});
		return lbl;
	}
	previewModal(asset){
		this.AS.previewAsset=asset.thumb;
		this.AS.previewName=asset.asset_name;
		this.AS.previewVidFile=asset.video_preview;
		this.AS.previewOpen=true;
	}
	usageGuide(usage){
		console.log('add usage tooltip');
	}
	toggleAsset(asset){
		if(asset.expand){
			asset.expand=false;
		}else{
			asset.expand=true;
		}
		console.log(asset.asset_id, asset.expand);
		console.log(asset);
		console.log(asset.campaigns);
	}
	addtoQ(asset){
		console.log('adding'+asset.asset_id);
		var json_data=[];
		var urlt='https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/assets/';
	    var filet= asset.path.replace(urlt,'');
				   
		json_data.push({profile_id:this.PS.profile_id});
		this.AS.addtoQueue(asset.asset_id,json_data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
			   if(data !== "success"){
				   console.log('fail');
				   this.CS.alert_message = "This submission encountered an error.  Please try again or contact <a href='mailto:support@hallmarkmediahub.com'>customer support</a>";
				   this.CS.showAlert = true;
			   }else{
		//		   console.log('newdownload');
				  // this.downloadFileFromUrl(urlt,filet);
				   this.S3S.secureDownload(asset.path);
				   this.AS.downloadSuccess=true;
				   this.CS.updateContent();
				   //this.router.navigate(['/asset-queue']);
				  
			   }
		   });	
	}
	
	downloadFileFromUrl(url: string, fileName: string): void {
    try {
        // Construct the download URL
        const downloadUrl = `${url}`;
//https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/assets/1_HC_CTC_AllSeasonLong_Keyart.zip
	//	asset.replace('https://s3.amazonaws.com/assets.hallmarkaffiliates/','');
        // Create a temporary anchor element
        const anchor = document.createElement('a');
        anchor.href = downloadUrl+fileName;
        anchor.target = '_blank'; // Open in a new tab, if needed
       // anchor.download = fileName;

        // Programmatically click the anchor element to trigger download
        anchor.click();
    } catch (error) {
        console.error('Error downloading file:', error);
        // Handle error as needed
    }
}
	
	
	
	clear_current(el){
		this.AS['current_'+el]=null;
		this.getFilteredAssets();
	}
	clearAll(){
		this.AS.assetSearch=null;
		this.AS.filteredCount=0;
		this.AS.current_network=null;
		this.AS.current_campaign=null;
		this.AS.current_type=null;
		this.AS.current_title=null;
		this.AS.current_tag=null;
		this.AS.current_sort='asset_end';
		this.current_asset=null;
		this.getFilteredAssets();
	}
	updateSearch(){
		this.AS.current_network=null;
		this.AS.current_campaign=null;
		this.AS.current_type=null;
		this.AS.current_title=null;
		this.AS.current_tag=null;
		this.AS.current_sort='asset_end';
		this.current_asset=null;
		this.getFilteredAssets();
	}
	
	excludeData(data, el, field){
		var arr=[];
		data.forEach(function(obj){
			console.log('el:',el);
			if(obj[field]!==el){
				//console.log('foeld:',field);
				arr.push(obj);	
			}
		});
		return arr;
	}
	filterData(data, el, field, subfield){
		var arr=[];
		if( el && !subfield){
			data.forEach(function(obj){
				if(obj[field]==el){
					arr.push(obj);	
				}
			});
			return arr;
		}else if(el){
			data.forEach(function(obj){
				obj[field].forEach(function(obj2){
					if(obj2[subfield]==el){
						arr.push(obj);	
					}
				})		
			});
			return arr;
		}else{
			return data;
		}
	}
	getFilteredAssets(){ 
		this.authorized=true;
		var arr=this.Assets;
		console.log('start', arr);
		arr=this.excludeData(arr, 'Network Assets', 'asset_type');
		arr=this.excludeData(arr, '0', 'active');
		arr=this.filterData(arr, this.AS.current_title, 'asset_name', false );
		arr=this.filterData(arr, this.AS.current_type, 'asset_type', false );
		arr=this.filterData(arr, this.AS.current_tag, 'tags', 'tag' );
		arr=this.filterData(arr, this.AS.current_campaign, 'campaign','campaign_name' );
		arr=this.filterData(arr, this.AS.current_network, 'networks', 'network_full' );
		this.FilteredAssets=arr;
		this.FilteredNetworks=this.processFilter('networks','network_id',arr);
		this.FilteredCampaigns=this.processFilter('campaign','campaign_name',arr);
	    this.FilteredTitles=this.processFilter(null,'asset_name',arr);
		this.FilteredTags=this.processFilter('tags','tag',arr);
		this.FilteredTypes=this.processFilter(null,'asset_type', arr);
		if(arr?.length==0 && (this.crit=='asset' || this.crit=='campaign')){
		   this.authorized=false
		   }
		console.log('end', arr);
		   console.log(this.authorized);
	}
	checkForAssets(id){
		
	}
	searchScroll(){
		window.scroll(1000, 600);		  
	}
	processFilter(col,fld,data){
		var arr=[];
		data.forEach(function(obj){
			if(col){
				obj[col].forEach(function(obj2){
					
					const isThere = (element) => element[fld] == obj2[fld];
					 var i = arr.findIndex(isThere);
					  if (i > -1) {
					  } else {
						  arr.push(obj2);
					  }
				});
			}else{
				const isThere = (element) => element[fld] == obj[fld];
				var i = arr.findIndex(isThere);
			  	if (i > -1) {
					
			  	} else {
				  arr.push(obj);
			  	}
			}
		})
		console.log('arr is', arr);
 		return arr
	}
	


	
	
//	ngAfterContentChecked() {
 //   	this.containersCount = this.containers?.length;
  //	}	

}
